import React, {createContext, useCallback, useContext, useMemo, useState} from "react";
import {IntlProvider} from "react-intl";

import {ApplicationContext, defaultMarvinConfiguration} from "infrastructure/contexts/contexts";

import {getTranslations} from "localization/utils";
import {useDayJs} from "localization/hooks/useDayJs";
import {getStoredLanguages, storeLanguage} from "../utils/getStoredLanguages";
import {resolveLanguages} from "../utils/resolveLanguages";
import {useAsRef} from "hooks/useAsRef";
import {useActiveModule} from "../../infrastructure/hooks/useActiveModule";
import {find, first} from "lodash";

type Props = React.PropsWithChildren<{}>;

export type LanguagesState = {
	/**
	 * core language
	 */
	core: string;

	/**
	 * modules language
	 */
	modules: { [code: string]: string }
}

type ILanguageContext = LanguagesState & {
	/**
	 * change current language
	 * @param language
	 */
	onChange: (language: string) => void;
}

export const LanguageContext = createContext<ILanguageContext>({
	modules: {},
	core: find(defaultMarvinConfiguration.cultures, c => !!c.default)?.code ?? first(defaultMarvinConfiguration.cultures)?.code ?? "en-GB",
	onChange: () => {
		throw new Error("Language context not initialized");
	}
});

/**
 * Component handles languages resolution, creates context for language change and current state, registers dayjs locale
 * @param children
 * @constructor
 */
export function Languages({children}: Props) {
	const application = useContext(ApplicationContext);
	const ref = useAsRef(application);

	const activeModule = useAsRef(useActiveModule());

	// resolve default languages from cache (combined with application context)
	const defaultLanguages = useMemo(() => {
		return resolveLanguages(activeModule.current, ref.current.marvinConfiguration, getStoredLanguages());
 	}, []);

	const [languages, setLanguages] = useState<LanguagesState>(defaultLanguages);

	const onChange = useCallback((culture) => {
		// change stored language (either active module or core)
		storeLanguage(culture, activeModule.current?.code);

		// set newly resolved languages
		setLanguages(resolveLanguages(activeModule.current, ref.current.marvinConfiguration, getStoredLanguages()));
	},[]);

	// register dayjs locale (only for core language as that is the only one that is used in core)
	useDayJs(languages.core);

	// wrap values to context
	const context = useMemo<ILanguageContext>(() => ({
		...languages,
		onChange
	}), [languages, onChange]);

	return (
		<IntlProvider locale={languages.core} messages={getTranslations(languages.core)}>
			<LanguageContext.Provider value={context}>
				{children}
			</LanguageContext.Provider>
		</IntlProvider>
	)
}
